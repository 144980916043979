.form-group {
    * {
        appearance: none;
    }

    > div {
        &.radio-inline {
            padding-left: 0;
            margin-bottom: 0;
        }
    }

    label, .control-label {
        color: lighten(palette('outerSpace'), 20%);
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;

        [class^="icon"] {
            font-size: 1.5rem;
            &:before {
                margin-top: 0;
            }
        }
    }

    &.required {
        .control-label:after {
            content: '*';
            color: $brand-danger;
        }

        &.correct {
            .control-label:after {
                @include icon-font();
                color: $brand-success;
                content: "m";
            }
        }
    }

}

fieldset {
    margin: 0 0 15px;
    width: 100%;
}

legend {
    border: none;
    color: lighten(palette('outerSpace'), 20%);
    font-weight: 600;
    margin: 0 0 15px;
}

.has-error {
    .control-label, .error, .checkbox-control .radio-control {
        color: $brand-danger;
    }
    .select2-container {
        border: 1px solid $brand-danger;
    }
}

.form-control {
    @include form-control;

    &.solo {
        margin-bottom: 15px;
    }
}

.search-control {
    margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(sm) {
    .form-group {
        label {
            //font-size: 1.25rem;
        }
    }
    fieldset {
        margin: 0 0 30px;
    }
    legend {
        font-size: 1.5rem;
        margin: 0 0 30px;
    }
}

.img-remove-link {
    font-size: $font-size-small;
    display: block;
    text-align: center;
    color: $brand-danger;
    padding: 2px;
    margin-top: 2px;

    &:hover {
        color: darken($brand-danger, 15%);
        background: #f0f0f0;
    }

    &:visited {
        color: $brand-danger;
    }
}

.file-remove-link {
    color: $brand-danger;

    &:hover {
        color: darken($brand-danger, 15%);
    }

    &:visited {
        color: $brand-danger;
    }

    &:before {
        content: "/ ";
    }
}

.form-intro {
    padding: ($grid-gutter-width / 2) 0;
}

.form-required-msg {
    color: #51696b;
    font-weight: 600;
    [class^="icon"]:before {
        margin-right: 2px;
        margin-left: 2px;
        color: $brand-danger;
    }
}

.iesw-show-password {
    border: none;
    cursor: pointer;
    text-align: center;

    i::before {
        margin-right: 0;
    }
}
