.btn {
    font-weight: 600;
    line-height: 2.75rem;
    padding: 0 15px 0;
    transition: background-color .2s;
}

.btn-sm {
    font-size: 14px;
    line-height: 1.5;
    border-radius: 4px;
    padding: 2px 10px;
}

.btn-lg {
    line-height: 3rem;
    font-size: 18px;
}

.btn-nav {
    @include button-variant(palette('pureWhite'), rgba(0,0,0,0), darken(palette('iceWhite'), 10%));
}

.btn-shortlist {
    @include button-variant(palette('pureWhite'), palette('lochmara'), palette('lochmara'));

    &.btn-shortlisted {
        @include button-variant(palette('pureWhite'), palette('mountainMeadow'), palette('mountainMeadow'));
    }

    [class*="icon-"]:before {
        font-size: 1.125rem;
    }

    &:hover, &:active, &:focus {
        outline: none;
    }
}

.btn-cancel {
    @include button-variant(palette('outerSpace'), lighten(palette('outerSpace'), 60%), lighten(palette('outerSpace'), 60%));
}

.btn-submit, .btn-primary {
    @include button-variant(palette('outerSpace'), palette('supernova'), palette('supernova'));
    &:hover, &:active {
        background-color: lighten(palette('supernova'), 10%);
    }
    &:focus, &:active:focus {
        background-color: lighten(palette('supernova'), 10%);
        outline: 1px solid darken(palette('supernova'), 5%);
    }
}

.btn-secondary, .btn-cta {
    background-color: palette('lochmara');
    color: palette('pureWhite');
    &:hover, &:active {
        background-color: lighten(palette('lochmara'), 10%);
        color: palette('pureWhite');
    }
    &:focus, &:active:focus {
        background-color: lighten(palette('lochmara'), 10%);
        color: palette('pureWhite');
        outline: 1px solid darken(palette('lochmara'), 5%);
    }
}

.btn-reg {
    max-height: 46px;
}

.btn-lang.en {
    @include button-variant(palette('pureWhite'), palette('lochmara'), palette('lochmara'));
}
.btn-lang.cy {
    @include button-variant(palette('pureWhite'), palette('thunderbird'), palette('thunderbird'));
}

.btn-publish {
    @extend .btn-success;
    @include btn-icon($icon-page-export, 18px, 18px, 18px, 1px);
}

.btn-unpublish {
    @extend .btn-warning;
    @include btn-icon($icon-page-delete, 18px, 18px, 18px, 1px);
}

.btn-trash {
    @include btn-icon($icon-trash, 18px, 18px, 10px, 1px);
    &.btn-sm {
        &:after {
            top: -1px;
        }
    }
}

.btn-edit {
    @include btn-icon($icon-page-edit, 18px, 18px, 10px, 1px);
    &.btn-sm {
        &:after {
            top: -1px;
        }
    }
}

.width-override{
    width: 135px !important;
}

.approve-toggle, .publish-toggle {
    width: 100%;
    &--approved, &--published {
        @include btn-icon($icon-tick, 18px, 18px, 10px, 1px);
        &.btn-sm {
            &:after {
                top: -1px;
            }
        }
    }
}

.btn-tick {
    @include btn-icon($icon-tick, 18px, 18px, 10px, 1px);
}

.btn-arrow-right {
    @include btn-icon($icon-chevron-right, 14px, 14px, 10px, 2px);
}

.btn-save {
    @extend .btn-primary;
    @extend .btn-tick;
}

.btn-add {
    @include btn-icon($icon-plus, 18px, 18px, 10px, 1px);
}

.btn-x {
    @extend .btn-danger;
    @include btn-icon($icon-x, 18px, 18px, 10px, 1px);
}

.btn-view {
    @include btn-icon($icon-facility-it, 14px, 14px, 10px, 2px);
    &.btn-sm {
        margin-top: 8px;

        &:after {
            top: 0;
        }
    }
}

.btn-choose-file {
    @include button-variant(palette('pureWhite'), palette('lochmara'), palette('lochmara'));
    color: #fff !important;
    font-weight: 600 !important;
    line-height: 2.75rem !important;
    padding: 0 15px 0 !important;
    transition: background-color .2s;
    cursor: pointer;

    &:hover, &:active, &:focus {
        outline: none;
    }
}
