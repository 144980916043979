// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}


/*
 * Font face
 */

@mixin font-face($name) {
    $filepath: "../../fonts/" + $name + "/" + $name;
    @font-face {
        font-family: "#{$name}";
        src: url($filepath + ".eot?v=20200519") format('embedded-opentype'),
             url($filepath + ".eot?#iefix") format('embedded-opentype'),
             url($filepath + ".woff?v=20200519") format('woff'),
             url($filepath + ".ttf?v=20200519")  format('truetype'),
             url($filepath + ".svg#20200519" + $name) format('svg');
    }
}


/*
 * Font size
 */

@function calculateRem($size) {
    $remSize: $size / $font-size-base;
    @return $remSize * 1rem;
}

@mixin font-size($font-size: $font-size-base, $line-height: null, $letter-spacing: null) {
    font-size: $font-size;
    font-size: calculateRem($font-size);
    @if $line-height { line-height: $line-height; }
    @if $letter-spacing { letter-spacing: $letter-spacing; }
}


/*
 * Icon font
 */

@mixin icon-font {
    display: inline-block;
    font-family: "fontastic" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 1;
    margin-right: 6px;
    margin-top: -.15em;
    speak: none;
    text-transform: none !important;
    vertical-align: middle;
}

@mixin form-control {
    background-color: palette('tropicalBlue');
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: none;
    color: palette('outerSpace');
    font-size: 1.25rem;
    height: auto;
    line-height: 2.813rem;
    min-height: 46px;
    padding: 0 0.9375rem 0;

    &:focus {
        box-shadow: none;
        outline: 4px solid palette('supernova');
    }
}

@mixin descending-z-index($count: 30){
  // @include on UL class
  position: relative;
  li {
    position: relative;
    $target: 0;
    $index: $count;
    @while $index > 0 {
      &:nth-child(#{$target}){ z-index: #{$index}; }
      $target: $target + 1;
      $index: $index - 1;
      }
    }
}

@mixin btn-icon($icon: "\69", $fontSize: 18px, $icon-width: 18px, $margin-left: 10px, $top: 0) {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &:after {
        @include icon-font;
        @include font-size($fontSize);
        position: relative;
        content: $icon;
        color: inherit;
        margin: 0;
        margin-left: $margin-left;
        margin-right: -3px;
        width: $icon-width;
        display: flex;
        justify-content: center;
        top: $top;
    }
}
