// Flex grid stop! (unreliable with BS4 alpha 2)

$enable-flex: false;

// We're all about sharp corners

$enable-rounded: false; // BS4

$border-radius-base:  0; // BS3
$border-radius-large: 0;
$border-radius-small: 0;

// Palette

$palette: (
    lochmara: #0077CC,
    tropicalBlue: #CBE5F8,
    mountainMeadow: #20BF55,
    supernova: #FEC601,
    outerSpace: #253031,
    pureWhite: #FFF,
    iceWhite: #F4F7F9,
    amaranth: #EB3150,
    thunderbird: #CA1F1F,
    zest: #F5931C
);

@function palette($color) {
    @return map-get($palette, $color);
}

// BS colours

$brand-primary:         palette('supernova');
$brand-secondary:       palette('lochmara');
$brand-success:         palette('mountainMeadow');
$brand-info:            #5bc0de;
$brand-warning:         palette('zest');
$brand-danger:          palette('amaranth');

$state-success-text:             #3c763d;
$state-success-bg:               #dff0d8;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text:                #31708f;
$state-info-bg:                  #d9edf7;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text:             #8a6d3b;
$state-warning-bg:               #fcf8e3;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text:              darken($brand-danger, 10%);
$state-danger-bg:                lighten($brand-danger, 40%);
$state-danger-border:            $state-danger-text;

// Breakpoints

$grid-breakpoints: (
  xs: 480px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

// Font / Text

$font-size-base: 16px;

// Pagination

$pagination-color:                     palette('pureWhite');
$pagination-bg:                        palette('outerSpace');
$pagination-border:                    palette('pureWhite');

$pagination-hover-color:               palette('pureWhite');
$pagination-hover-bg:                  lighten(palette('outerSpace'), 15%);
$pagination-hover-border:              palette('pureWhite');

$pagination-active-color:              palette('outerSpace');
$pagination-active-bg:                 palette('supernova');
$pagination-active-border:             palette('pureWhite');

$pagination-disabled-color:            palette('pureWhite');
$pagination-disabled-bg:               lighten(palette('outerSpace'), 50%);
$pagination-disabled-border:           palette('pureWhite');

// Wells

$well-bg: palette('iceWhite');
$well-border: transparent;
