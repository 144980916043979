//== REM margins
//

@for $i from 0 through 4 {
    .mt-#{$i} {
        margin-top: #{$i}rem;
    }
}

@for $i from 0 through 4 {
    .mb-#{$i} {
        margin-bottom: #{$i}rem;
    }
}

@for $i from 0 through 4 {
    .ml-#{$i} {
        margin-left: #{$i}rem;
    }
}

@for $i from 0 through 4 {
    .mr-#{$i} {
        margin-right: #{$i}rem;
    }
}

.fade-in {
    opacity: 0;
    transition: 0.2s ease;
    &.fade-in--ready {
        opacity: 1;
    }
}
