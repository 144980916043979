.radio-group {
    @include clearfix();

    .radio {
        float: left;
        margin-right: 15px;
        margin-top: 0;

        + .radio {
            margin-top: 0;
        }
    }

    .radio-control {
    	display: table-cell;
    	cursor: pointer;
    	height: 40px;
    	line-height: 40px;
    	margin-top: 0;
    	position: relative;
    	padding: 0 0 0 55px;
    	top: 50%;
    	vertical-align: middle;

    	&:before {
    		border: 5px solid darken(palette('tropicalBlue'), 15%);
    		border-radius: 20px;
    		color: palette('lochmara');
    		content: " ";
    		display: inline-block;
    		font-size: 18px;
    		height: 40px;
    		line-height: 27px;
    		left: 0;
    		position: absolute;
            transition: border-color .15s;
    		text-align: center;
    		width: 40px;
    		top: 0;
    	}
    }

    .radio-control-switch {
        cursor: pointer;
        height: 47px;
        width: 50%;
        text-align: center;
        line-height: 47px;
        border: 1px solid lighten(palette('outerSpace'), 50%);
        color: palette('outerSapce');
        background-color: palette('iceWhite');
        float: left;
    }

    .img-radio-control {
        border: 5px solid lighten(palette('tropicalBlue'), 15%);
        padding: 0;

        img {
            opacity: .75;
        }

    }
}

.checkbox-control {
	display: table-cell;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
    margin-top: 0;
	position: relative;
	padding: .125em 0 0 55px;
	top: 50%;
	vertical-align: middle;

	&:before {
        border: 5px solid darken(palette('tropicalBlue'), 15%);
		color: palette('lochmara');
		content: " ";
		display: inline-block;
		font-size: 18px;
		height: 40px;
		line-height: 27px;
		left: 0;
		position: absolute;
        transition: border-color .15s;
		text-align: center;
		width: 40px;
		top: 0;
	}
}

.radio-group input[type=radio],
.checkbox-group input[type=checkbox] {
    display: none;
    height: 0;
    width: 0;
    position: absolute;
    top: -9999em;
}
.radio-group input[type=radio] + .radio-control:after {
    background-color: palette('lochmara');
    border-radius: 0;
    content: " ";
    display: block;
    height: 0;
    left: 20px;
    width: 0;
    opacity: 0;
    position: absolute;
    top: 20px;
    transition: all .15s;
}

// .radio-group input[type=radio] + .radio-control-switch:after {
//     background-color: palette('lochmara');
//     border-radius: 0;
//     content: " ";
//     display: block;
//     height: 0;
//     left: 20px;
//     width: 0;
//     opacity: 0;
//     position: absolute;
//     top: 20px;
//     transition: all .15s;
// }

.checkbox-group-heading {
    margin-top: 15px;
    .checkbox-control {
        line-height: 1;
    }
}

// Hover states


.radio-group .radio-control:hover:before,
.radio-group input[type=radio]:focus + .radio-control:before,
.checkbox-group .checkbox-control:hover:before {
    border: 5px solid darken(palette('tropicalBlue'), 15%);
}

// Checked states

.radio-group input[type=radio]:checked + .radio-control:before,
.radio-group input[type=radio]:checked + .img-radio-control,
.checkbox-group input[type=checkbox]:checked + .checkbox-control:before {
    border-color: palette('lochmara');
}

.radio-group input[type=radio]:checked + .radio-control-switch {
    background-color: palette('lochmara');
    color: palette('pureWhite');
    border-color: lighten(palette('lochmara'), 15%);
}

// Focus states
.radio-group input[type=radio]:focus + .radio-control:before,
.checkbox-group input[type=checkbox]:focus + .checkbox-control:before  {
    box-shadow: 0 0 0 4px palette('supernova');
}
.radio-group input[type=radio]:checked + .radio-control:after{
    border-radius: 20px;
    height: 20px;
    left: 10px;
    opacity: 1;
    top: 10px;
    width: 20px;
}


.radio-group input[type=radio]:checked + .img-radio-control {
        img {
            opacity: 1;
        }
}

.checkbox-group input[type=checkbox] + .checkbox-control:after {
    @include icon-font;

    background: transparent;
    border: none;
    color: palette('lochmara');
    content: "m"; // 'Tick'
    font-size: 0;
    height: 40px;
    left: 0;
    line-height: 48px;
    opacity: 1;
    position: absolute;
    text-align: center;
    top: 0;
    width: 40px;
    transition: all .15s;
}

.checkbox-group input[type=checkbox]:checked + .checkbox-control:after {
    font-size: 30px;
}

.checkbox-group-item {
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 30px;
    }
}

.radio-group-switch {
    display: flex;
    flex-direction: column;
    .radio-control-switch {
        flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        height: auto!important;
        line-height: 1.6;
        padding: 12px;
        &__subtitle {
            @include font-size(14px);
        }
    }
}
