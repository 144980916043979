@include font-face(fontastic);

a:hover, a:focus {
    [class^="icon"],
    [class*=" icon-"] {
        text-decoration: none;
    }

    &[class^="icon"],
    &[class*=" icon-"] {
        text-decoration: none;
    }
}

[class^="icon"]:before,
[class*=" icon-"]:before {
    @include icon-font;
}

// General UI icons

$icon-page-export: "\32";
$icon-page-delete: "\33";
$icon-page-edit: "\35";
$icon-trash: "\34";
$icon-x: "M";
$icon-tick: "m";
$icon-attention: "1";
$icon-chevron-right: "k";
$icon-plus: "f";
$icon-facility-it: "Z";

.icon-star-o:before {
    content: "a";
}

.icon-eye:before {
    content: "b";
}

.icon-locator:before {
    content: "d";
}

.icon-tag:before {
    content: "g";
}

.icon-tags:before {
    content: "h";
}

.icon-chevron-right:before {
    content: $icon-chevron-right;
}

.icon-tick:before {
    content: $icon-tick;
}

.icon-profile:before {
    content: "p";
}

.icon-flashlight:before {
    content: "q";
}

.icon-twitter:before {
    content: "s";
}

.icon-star:before {
    content: "o";
}

.icon-plus:before {
    content: $icon-plus;
}

.icon-cog-o:before {
    content: "c";
}

.icon-burger:before {
    content: "e";
}

.icon-language:before {
    content: "i";
}

.icon-phone:before {
    content: "j";
}

.icon-link:before {
    content: "l";
}

.icon-envelope-o:before {
    content: "n";
}

.icon-facebook:before {
    content: "r";
}

.icon-document-o:before {
    content: "t";
}

.icon-information:before {
    content: "u";
}

.icon-certificate:before {
    content: "v";
}

.icon-eye-strike:before {
    content: "w";
}

.icon-search:before {
    content: "x";
}

.icon-target:before {
    content: "y";
}

.icon-crosshair:before {
    content: "z";
}

.icon-expand:before {
    content: "A";
}

.icon-collapse:before {
    content: "B";
}

.icon-compass:before {
    content: "C";
}

.icon-service:before {
    content: "D";
}

.icon-organisation:before {
    content: "E";
}

.icon-x:before {
    content: $icon-x;
}

.icon-clock:before {
    content: "L";
}

.icon-documents:before {
    content: "N";
}

.icon-images:before {
    content: "O";
}

.icon-logout:before {
    content: "P";
}

.icon-login:before {
    content: "Q";
}

.icon-chevron-down:before {
    content: "R";
}

.icon-chevron-left:before {
    content: "S";
}

.icon-chevron-up:before {
    content: "T";
}

.icon-attention:before {
    content: $icon-attention;
}

.icon-page-export:before {
    content: $icon-page-export;
}
.icon-page-delete:before {
    content: $icon-page-delete;
}
.icon-page-edit:before {
  content: $icon-page-edit;
}
.icon-trash:before {
    content: $icon-trash;
}


// Facility icons

.icon-facility-conference:before {
    content: "W";
}
.icon-facility-dance-floor:before {
    content: "V";
}
.icon-facility-disabled-facility:before {
    content: "K";
}
.icon-facility-equipment:before {
    content: "0";
}
.icon-facility-hearing-loop:before {
    content: "J";
}
.icon-facility-internet-wifi:before {
    content: "G";
}
.icon-facility-internet-no-wifi:before {
    content: "U";
}
.icon-facility-it:before {
    content: $icon-facility-it;
}
.icon-facility-kitchen:before {
    content: "H";
}
.icon-facility-pa-system:before {
    content: "Y";
}
.icon-facility-parking:before {
    content: "I";
}
.icon-facility-refreshment:before {
    content: "X";
}
.icon-facility-toilets:before {
    content: "F";
}
.icon-home-house-streamline:before {
  content: "\36";
}
.icon-sort:before {
    content: "\37";
}
.icon-lock:before {
    content: "\38";
}
