@import url('//fonts.googleapis.com/css?family=Titillium+Web:400,600,300');

// Base

html {

}

html, body {
    color: palette('outerSpace');
    font-family: 'Titillium Web', sans-serif;
    font-size: $font-size-base;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Headings

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

h1 {
    font-size: 2.25rem;
}

.text-with-info-tt {
    display: inline-flex;
    flex-wrap: nowrap;
    .info-tooltip {
        margin-left: 4px;
    }
}

.text-lg {
    font-size: 1.25rem;
}
