// Hide the things

.print,
.main-header,
.main-footer,
form,
.service-browser,
#shortlist-choice-wrapper,
.new-shortlist-input-button,
.well,
.btn-shortlist {
    display: none;
    visibility: hidden;
}

// Show the things
.print {
    display: block;
    visibility: visible;
}

.print-branding {
    display: block;
    margin: 15px 0 0 15px;
    width: 35%;
}

// Typography

html, body {
    font-size: 14px;
}

// Disable BS links

a[href]:after {
    content: none !important;
}

abbr[title]:after {
    content: none !important;
}

.print-page-break {
    display: block;
    page-break-after: always;

    &:last-of-type {
        page-break-after: avoid;
    }
}
