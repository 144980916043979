.profile {

    .shortlist-container {
        @include clearfix();
        padding-bottom: 30px;
        padding-top: 50px;
    }

    .heading {
        color: #0077cc !important;
        clear: both;
        font-size: 5.0rem;
    }

    .sub-heading, .related-heading {
        font-size: 3rem;
    }

    .section-heading {
        font-size: 2rem;
    }

    .info-container {
        @include clearfix();
        float: left;
        width: 100%;

        .col {
            width: 100%;
        }

        .info-section {
            margin: 0;
            padding: 0 15px 0 0;
            width: 100%;
        }
    }

    .info-section {
        .heading {
            font-size: 1.5rem;
        }
        .address-line{
            font-size: 1.2rem;
            line-height: 1.75;
            color: #5c5c5c !important;
        }
        .contact-line{
            font-size: 1.2rem;
            line-height: 1.75;
            color: #5c5c5c !important;
        }

    }

    .overview {
        width: 100%;

        .description {
            font-size: 1.2rem;
            line-height: 1.75;
            color: #5c5c5c !important;
        }
    }

    .btn {
        display: none;
        visibility: hidden;
    }

    .hidden-contact{
        display: block;
    }

}

.print-cover {

    margin-bottom: 400px;

    .cover-title{
        display:block;
        width:100%;
        .title-image{
            float: right;
            width: 45%;
            margin: 50px 50px 200px 0px;
        }
    }

    .cover-user{
        clear:right;
        margin-bottom: 30px;
    }

    .cover-image{
        display:block;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        margin-bottom: 50px;
    }

    .cover-information{
        background-color: #0077cc !important;
        -webkit-print-color-adjust: exact;
        padding-top: 10px;
        padding-bottom: 30px;

        h2{
            margin-left: 10px;
            color: white !important;
        }
    }

}

.terms-and-conditions-compact{
    display:block;
}


.terms-and-conditions{

    padding-top: 30px;

    p{
        font-size: 1.2rem;
        line-height: 1.75;
        color: #5c5c5c !important;
    }

    .section-heading {
        font-size: 2rem;
        margin-bottom: 20px
    }
}
